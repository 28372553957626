<template>
  <div class="message" id="chatMessage" :class="chatContClass">
    <!-- 左边显示 -->
    <div
      class="left"
      v-for="(item, index) in messageLists"
      :key="index"
      :class="item.userid === userid ? 'right' : ''"
    >
      <!-- <div>{{userid}}</div> -->
      <img
        v-if="item.userid !== userid"
        :src="item.userimg || headImg()"
        class="portrait"
      />
      <!-- <div class="portrait" v-show="item.userid === userid"></div> -->
      <div class="information">
        <span class="number text-color" v-if="item.userid !== userid">{{
          item.username ? item.username : item.userphone
        }}</span>
        <span class="numberright text-color" v-show="item.userid === userid">{{
          item.username ? item.username : item.userphone
        }}</span>
        <!-- <div class="new-item-info">
          <span>{{ item.userphone }}</span>
        </div> -->
        <van-cell-group v-if="item.lottery">
          <van-cell title="cược số" :value="item.lottery.expect" />
          <van-cell title="kiểu chơi" :value="methodCn2(item.lottery.method)" />
          <van-cell title="Đặt cược của tôi" :value="item.lottery.code" />
          <van-cell
            title="Số tiền đặt cược"
            :value="'₫' + item.lottery.totalMoney"
          />
        </van-cell-group>
        <div v-else :class="item.userid === userid ? 'numberright' : 'number'">
          <span class="text" v-if="item.tag === 'text'">{{
            item.content
          }}</span>
          <viewer :images="[{ url: chatImgUlr() + item.content }]" v-else>
            <img :src="chatImgUlr() + item.content" class="message-img" />
          </viewer>
        </div>
      </div>
      <!-- 当前登录的用户 -->
      <img
        v-show="item.userid === userid"
        :src="item.userimg || headImg()"
        class="portrait rightportrait"
      />
    </div>
    <div v-if="messageLists.length >= 1" class="Wish">
      nhận được một giải thưởng lớn !
    </div>
  </div>
</template>
<script>
import { methodCn } from '@/utils/unique'
import { chatImg } from '@/utils/request'

export default {
  name: '',
  components: {},
  props: {
    lotteryType: {
      type: Object
    },

    userid: {
      type: [Number, String]
      //   required: true
    },
    gameType: {
      type: String
    }
  },
  data () {
    return {
      list: [],
      messageLists: [], // 保存历史聊天记录
      includeColor: ['màu xanh lá', 'màu tía', 'màu đỏ'],
      Message: {},
      userInfos: window.localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},
      userToken: window.localStorage.userToken,
      chatContStyle: {},
      chatContClass: '',
      isScroll: true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.getPageData, true)
  },
  created () {
    if (this.gameType === 'all') {
      this.chatContStyle = {
        height: 'calc(100% - 450px)',
        top: '295px'
      }
      this.chatContClass = 'message1'
    } else {
      this.chatContStyle = {
        height: 'calc(100% - 480px)',
        top: '390px'
      }
      this.chatContClass = 'message2'
    }
    this.userToken = window.localStorage.userToken
  },
  updated () {
    if (this.isScroll) {
      this.scrollToBottom()
      this.isScroll = false
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.getPageData, true)
  },

  watch: {
    Message: function (val) {
      if (val.isScroll || val.username === this.userInfos.username) {
        this.isScroll = val
        this.scrollToBottom()
      } else {
        this.isScroll = false
      }
      this.messageLists.push(val)
    }
  },
  methods: {
    chatImgUlr () {
      return `${chatImg}`
    },
    headImg () {
      return `${chatImg}statics/images/user_head.png`
    },
    infoTextShow (text) {
      if (text === 'màu xanh lá') {
        return 'gấu trúc'
      } else if (text === 'màu tía') {
        return 'Rồng'
      } else if (text === 'màu đỏ') {
        return 'con hổ'
      } else {
        return text
      }
    },
    methodCn2 (val) {
      return methodCn(val)
    },

    // 获取历史聊天记录

    scrollToBottom () {
      var div = document.getElementById('chatMessage')
      div.scrollTop = div.scrollHeight
    },

    getPageData () {
      const chatMessage = document.getElementById('chatMessage')
      if (!chatMessage.scrollTop) {
        this.$emit('getPageData')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.message2 {
  height: calc(100% - 480px);
  top: 390px;
}
.message {
  width: 99.5%;
  overflow: auto;
  position: fixed;
  top: 95px;
  padding: 30px 0 0 10px;
  box-sizing: border-box;
  .right {
    display: flex;
    justify-content: flex-end;
  }
  .left {
    display: flex;
    margin-bottom: 60px;
    /deep/ .van-cell {
      height: 70px;
      padding: 0 24px;
      line-height: 70px;
    }
    /deep/ .van-cell__title {
      font-weight: 700;
    }
    /deep/ .van-cell__value {
      color: #ff0000;
    }
    .portrait {
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }
    .rightportrait {
      position: relative;
      top: 0;
      right: 0;
      margin-left: 20px;
    }
    .number {
      font-size: 30px;
      color: #999;
      max-width: 500px;
    }
    .numberright {
      display: inline-block;
      max-width: 500px;
      text-align: right;
      font-size: 30px;
      color: #000;
      margin-top: 15px;
      word-wrap:break-word;
    }
    .text-color {
      color: #a8a5a5;
    }
    .new-item-info {
      margin-top: 36px;
      width: 500px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 26px;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #f6f7f8;
      padding: 24px 14px;
      box-sizing: border-box;
      > span {
        color: #050506;
      }
      > p {
        color: #ff0000;
      }
    }
    .information {
      display: flex;
      flex-direction: column;
      // width: 500px;
      // height: 300px;
      .text {
        padding: 10px 20px;
        font-size: 26px;
        background-color: #fff;
        display: block;
      }
    }
    .text {
      font-size: 34px;
    }
    .message-img {
      width: 200px;
      height: 300px;
    }
  }
  .Wish {
    margin: 20px 0;
    height: 30px;
    font-family: 'PingFang-Regular';
    font-size: 26px;
    text-align: center;
    line-height: 30px;
  }
}
</style>
